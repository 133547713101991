<template>
    <div style="height:400px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <h4> Welcome </h4>
    <p style="text-align: center">You will be able to see a <br> preview for your designer</p>
    </div>
</template>

<script>
export default {
    name:'StartPreview'
}
</script>

<style>

</style>